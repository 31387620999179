import { default as about96auGzrvjQMeta } from "/home/les/dev/blob/pages/about.vue?macro=true";
import { default as adminJTXzERBdtgMeta } from "/home/les/dev/blob/pages/admin.vue?macro=true";
import { default as _91id_93aEjSETYs6yMeta } from "/home/les/dev/blob/pages/b/[id].vue?macro=true";
import { default as blobs1IQF5ZbfV9Meta } from "/home/les/dev/blob/pages/blobs.vue?macro=true";
import { default as indexEW2jGwVlupMeta } from "/home/les/dev/blob/pages/index.vue?macro=true";
import { default as _91id_938ko52qpyheMeta } from "/home/les/dev/blob/pages/p/[id].vue?macro=true";
import { default as _91id_93MeDdWvQn44Meta } from "/home/les/dev/blob/pages/s/[id].vue?macro=true";
import { default as search_testhUsSUyJGToMeta } from "/home/les/dev/blob/pages/search_test.vue?macro=true";
import { default as searchCSTRaKS2ChMeta } from "/home/les/dev/blob/pages/search.vue?macro=true";
import { default as sharehxEW43ocOQMeta } from "/home/les/dev/blob/pages/share.vue?macro=true";
import { default as signinSg6EIH0oOWMeta } from "/home/les/dev/blob/pages/signin.vue?macro=true";
import { default as sourceyTpka1YV6vMeta } from "/home/les/dev/blob/pages/source.vue?macro=true";
import { default as _91id_93MqKxRswHClMeta } from "/home/les/dev/blob/pages/tag/[id].vue?macro=true";
import { default as tagsAf2oslkSmGMeta } from "/home/les/dev/blob/pages/tags.vue?macro=true";
import { default as widgetZURxCkFwjQMeta } from "/home/les/dev/blob/pages/widget.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/les/dev/blob/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminJTXzERBdtgMeta || {},
    component: () => import("/home/les/dev/blob/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "b-id",
    path: "/b/:id()",
    component: () => import("/home/les/dev/blob/pages/b/[id].vue").then(m => m.default || m)
  },
  {
    name: "blobs",
    path: "/blobs",
    component: () => import("/home/les/dev/blob/pages/blobs.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/les/dev/blob/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/home/les/dev/blob/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: "s-id",
    path: "/s/:id()",
    component: () => import("/home/les/dev/blob/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: "search_test",
    path: "/search_test",
    component: () => import("/home/les/dev/blob/pages/search_test.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: searchCSTRaKS2ChMeta || {},
    component: () => import("/home/les/dev/blob/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/home/les/dev/blob/pages/share.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/home/les/dev/blob/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "source",
    path: "/source",
    component: () => import("/home/les/dev/blob/pages/source.vue").then(m => m.default || m)
  },
  {
    name: "tag-id",
    path: "/tag/:id()",
    component: () => import("/home/les/dev/blob/pages/tag/[id].vue").then(m => m.default || m)
  },
  {
    name: "tags",
    path: "/tags",
    component: () => import("/home/les/dev/blob/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: "widget",
    path: "/widget",
    component: () => import("/home/les/dev/blob/pages/widget.vue").then(m => m.default || m)
  }
]