import revive_payload_client_4sVQNw7RlN from "/home/les/dev/blob/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/les/dev/blob/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/les/dev/blob/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/les/dev/blob/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/les/dev/blob/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/les/dev/blob/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/les/dev/blob/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/les/dev/blob/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/les/dev/blob/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/les/dev/blob/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _0_mitt_oROMKtszZd from "/home/les/dev/blob/plugins/0.mitt.js";
import _1_dialog_xykKIlBKot from "/home/les/dev/blob/plugins/1.dialog.js";
import _2_vuetify_LYMqntGh3T from "/home/les/dev/blob/plugins/2.vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  _0_mitt_oROMKtszZd,
  _1_dialog_xykKIlBKot,
  _2_vuetify_LYMqntGh3T
]