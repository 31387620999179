import { createVuetify } from 'vuetify';
// import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import colors from 'vuetify/lib/util/colors.mjs'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    defaultAssets: false,
    treeShake: true,
    options: {
      customProperties: true
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    defaults:{
      VTextField: {
        density: 'compact'
      },
      VFileInput: {
        density: 'compact'
      },
      VSelect: {
        density: 'compact'
      },
      VAutocomplete: {
        density: 'compact'
      },
      VCombobox: {
        density: 'compact'
      },
      global: {
        elevation: '0',
        elevated: '0',
      },
      VCard: {
        elevation: 0,
        elevated: 0,
      },
      VChip: {
        variant: 'outlined',
        label: true,
        size: 'small'
      },
      VSwitch: {
        density: 'compact',
        inset: true
      },
      VDialog: {
        VCardTitle: {
          class: 'bg-secondary'
        },
        VCardActions: {
          VBtn: {
            variant: 'flat'
          }
        }
      },
      VBtn: {
        variant: 'flat',
        rounded: 0
      },
      // VTextField: {
      //   variant: 'solo'
      // }
      VDataTable: {
        density: 'compact',
      },
      VDataTableRow: {
        density: 'compact'
      },
      VDataTableItem: {
        density: 'compact'
      }      
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: colors.red.base,
            secondary: colors.indigo.base,
            accent: colors.blue.accent1,
            error: colors.red.accent4,
            info: colors.blue.base,
            success: colors.green.base,
            warning: colors.red.darken1,
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: colors.purple.darken4,
            secondary: colors.grey.darken1,
            accent: colors.pink.accent2,
            error: colors.red.darken2,
            info: colors.blue.base,
            success: colors.green.darken2,
            warning: colors.orange.darken1,
          },
        },
      },
    },
    directives,
  })

  nuxtApp.vueApp.use(vuetify)
})